/* eslint-disable no-console*/

<template>

<MDBRow>
<MDBNavbar id="navbar" class="navbar gradient-custom py-0 px-0" />
<MDBNavbar id="navbar" expand="lg" container >
    <MDBNavbar id="navbar" class="navbar gradient-custom" />
	<MDBNavbarBrand center class="mx-auto ms-5 ps-5 my-2" href="#">
	<img
        src="https://disc4all.upf.edu/wp-content/uploads/2021/09/Disc4all_120.png"
        class="h-auto w-75"
        loading="lazy"
      />
    </MDBNavbarBrand>
    <MDBNavbarToggler
      @click="collapse1 = !collapse1"
      target="#navbarSupportedContent"
      class="toggler ms-0"
    ></MDBNavbarToggler>
    <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
      <MDBNavbarNav right class="align-items-center">
        <RouterLink class="MDBNavbarItem prova" to="/" active style="color: #000221">
          Home
        </RouterLink>
        <MDBNavbarItem class="MDBNavbarItem prova" to="/launch" href="#">
          Launch
	</MDBNavbarItem>
	<MDBNavbarItem class="prova" href="#">
           <MDBDropdown class="nav-item" v-model="dropdown1">
            <MDBDropdownToggle
              tag="a"
              class="nav-link" 
              @click="dropdown1 = !dropdown1"
              >Help</MDBDropdownToggle>
            <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
              <RouterLink href="#" class="MDBDropdownItem prova" to="/tools" style="font-size: 12px">Sources</RouterLink>
              <MDBDropdownItem href="#" class="prova" style="font-size: 12px">Methods</MDBDropdownItem>
              <MDBDropdownItem href="#" class="prova" style="font-size: 12px">Good practices</MDBDropdownItem>
                <MDBDropdownItem divider />
              <MDBDropdownItem href="#" class="prova" style="font-size: 12px">References</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>
        <MDBBtn class="me-md-2 fw-bolder button1">
          Platform Login
        </MDBBtn>
        <MDBBtn class="me-md-2 fw-bolder prova1">
          Contact Us
        </MDBBtn>
      </MDBNavbarNav>
    </MDBCollapse>
  </MDBNavbar>
<MDBModal  tabindex="-1" style="position: static; display: block; height: auto;  background-color: rgba(3, 11, 118, 0.91)" :modelValue="true" removeBackdrop :keyboard="false" :focus="false">

                <MDBModalHeader>
                        <MDBModalTitle style="color: #000221"> Attention! </MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody style="color: #000221"> The site is under counstruction! </MDBModalBody>
</MDBModal>

</MDBRow>
</template>

<script>



import {
    MDBRow, 
    MDBBtn,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
  } from 'mdb-vue-ui-kit';
  import { ref } from 'vue';

  export default {
    components: {
      MDBRow,
      MDBBtn,
      MDBNavbar,
      MDBNavbarToggler,
      MDBNavbarBrand,
      MDBNavbarNav,
      MDBNavbarItem,
      MDBCollapse,
      MDBDropdown,
      MDBDropdownToggle,
      MDBDropdownMenu,
      MDBDropdownItem,
      MDBModal,
      MDBModalHeader,
      MDBModalTitle,
      MDBModalBody,
    },
    setup() {
      const collapse1 = ref(false);
      const dropdown1 = ref(false);
      return {
        collapse1,
        dropdown1,
      }
    },
  };


</script>

<style>

.gradient-custom {
  /* fallback for old browsers */
  background: #001a8f;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(0, 26, 143, 0.75), #FEFFCD);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgba(0, 2, 33, 0.85), #FEFFCD);

  height: 10px;
}


#navbar {
    border-collapse: collapse;
}

#navbar MDBNavbar {

   padding: 0; 
   margin: 0;
}

.prova {

  font-family: 'Crimson Text', serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  padding: 5px 15px 4px;
  color:  rgba(0.0.61);
}

.prova1 {

  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-size: 13px;
  padding: 5px 15px 4px;
  background: rgba(0, 2, 33, 0.85);
  color: rgba(255, 239, 61, 0.75);

}


.button1 {
  background-color: rgba(255, 239, 61, 0.45);
  color: #000221;
  padding: 5px 15px 4px;
  font-family: 'Crimson text', serif;
  font-weight: 400;
  font-size: 15px;

}

.toggler {
  color: rgba(0, 2, 33, 0.85);
}


</style>                       
