<template>

    <Slideshow />

</template>

<script>
// @ is an alias to /src
import Slideshow from '@/components/Slideshow.vue'

export default {
  name: 'Slideview',
  components: {
    Slideshow
  }
}
</script>
