import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SlideView from '../views/SlideView.vue'
import TableView from '../views/TableView.vue'

const routes = [
  {
    path: '/launch',
    name: 'launch',
    component: HomeView
  },
   {
    path: '/',
    name: 'home',
    component: SlideView
  },
  {
    path: '/tools',
    name: 'tools',
    component: TableView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
