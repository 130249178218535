<template>

    <Launch />

</template>

<script>
// @ is an alias to /src
import Launch from '@/components/Launch.vue'

export default {
  name: 'Homeview',
  components: {
    Launch
  }
}
</script>
