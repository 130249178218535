/* eslint-disable no-console */


<template>

<MDBCol xxl class="slide" style="background-color:rgba(29, 37, 81, 1); width: 100.60%">
	<MDBRow class="mx-5 py-5 slide" style="background-color: rgba(29, 37, 81, 1)">
		<div class="d-flex justify-content-left align-items-center h-100" style="background-color: rgba(11, 21, 78, 1)">
		<div class="ps-5" style="background-color: rgba(11, 21, 78, 1);  color: yellow">
		<h2 style="color: white">Launch Disc4All Simulations on e-platforms</h2>
		</div>
		</div>
	</MDBRow>

</MDBCol>


<MDBCol xxl class="slide" style="background-color: #000221; width: 100%">

	<MDBRow center class="pe-5 slide" style="background-color: #000221">
		<MDBCard class="mb-3 ms-5" style="width: 75%">
			<MDBRow class="g-0">
				<MDBCol md="2" class="pt-3">
					<MDBCardImg fluid class="pb-3" src="https://covid19dataportal.es/images/working-spaces/vre-logo.svg" alt="..."/>
				</MDBCol>
				<MDBCol md="10">
					<MDBCardBody>
					<MDBCardTitle style="color: #000221">Launch on OpenVRE</MDBCardTitle>
					<MDBCardText style="color: #000221">
					The Disc4All tools and workflows are integrated in the OpenVRE Virtual Research Environment, a platform that supports the expanding investigation in Intervertebral Disc Degeneration by developing tools to integrate the diagnosis in MRI images, genomic and phenotipic data to simulations in 2D/3D.
					</MDBCardText>
					<MDBCardText>
						<MDBBtn class="me-md-2 fw-bolder button1" @click="VRE" href="#!">Launch on OpenVRE <MDBIcon icon="cubes" /></MDBBtn>
					</MDBCardText>
					</MDBCardBody>
				</MDBCol>
				</MDBRow>
		</MDBCard>
	</MDBRow>


        <MDBRow center class="pe-5 slide" style="background-color: #000221">
                <MDBCard class="mb-3 ms-5" style="width: 75%">
                        <MDBRow class="g-0">
                                 <MDBCol md="2" class="pt-2 pb-2">
                                        <MDBCardImg fluid src="https://galaxyproject.org/images/galaxy-logos/galaxy_project_logo_square.png" alt="..."/>
                                </MDBCol>
                                <MDBCol md="10">
                                        <MDBCardBody>
                                        <MDBCardTitle style="color: #000221">Launch on Galaxy</MDBCardTitle>
                                        <MDBCardText style="color: #000221">
                                        The Disc4All tools and workflows are integrated on Galaxy, a platform for non-expert researchers who wants to carry out the investigation in Intervertebral Disc Degeneration using pre-diagnosis workflows starting from MRI images, genomic and phenotipic data to simulations in 2D/3D.
                                        </MDBCardText>
                                        <MDBCardText>
                                                <small class="text-muted">Under development</small>
                                        </MDBCardText>
                                        </MDBCardBody>
                                </MDBCol>
                                </MDBRow>
                </MDBCard>
        </MDBRow>


        <MDBRow center class="pe-5 slide" style="background-color: #000221">
                <MDBCard class="mb-3 ms-5" style="width: 75%">
                        <MDBRow class="g-0">
                                 <MDBCol md="2" class="pt-2 pb-2">
                                        <MDBCardImg fluid src="https://friconix.com/png/fi-stluxx-jupyter-notebook.png" alt="..."/>
                                </MDBCol>
                                <MDBCol md="10">
                                        <MDBCardBody>
                                        <MDBCardTitle style="color: #000221">Launch on Jupyter Notebooks</MDBCardTitle>
                                        <MDBCardText style="color: #000221">
                                        The Disc4All tools and workflows are integrated on Jupyter Notebooks, on easy-to-use and interactive notebooks that supports the expanding investigation in Intervertebral Disc Degeneration using stand-alone tools and/or workflows for the pre-diagnosis starting from MRI images, genomic and phenotipic data to simulations in 2D/3D.
                                        </MDBCardText>
                                        <MDBCardText>
                                                <small class="text-muted">Under development</small>
                                        </MDBCardText>
                                        </MDBCardBody>
                                </MDBCol>
                                </MDBRow>
                </MDBCard>
        </MDBRow>

	<MDBRow center class="pe-5 slide" style="background-color: #000221">
                <MDBCard class="mb-3 ms-5" style="width: 75%">
                        <MDBRow class="g-0">
                                 <MDBCol md="2" class="pb-4">
                                        <MDBCardImg fluid class="mt-3 px-4" src="https://insilicotrials.com/wp-content/uploads/2021/03/InSilicoTrials-Logotype-Black-HiRes-171x30.png" alt="..."/>
                                </MDBCol>
                                <MDBCol md="10">
                                        <MDBCardBody>
                                        <MDBCardTitle style="color: #000221">Launch on InSilicoTrials Platform</MDBCardTitle>
                                        <MDBCardText style="color: #000221">
                                        The Disc4All tools and workflows are integrated also on the InSilicoTrials Technologies platform, based in Trieste, Italy, a company which has the aim to make Model and Simulations, as well as AI,  easy and cost-effective and making it accesible to big, small and mid-sized companies. 
                                        </MDBCardText>
                                        <MDBCardText>
                                                <small class="text-muted">Under development</small>
                                        </MDBCardText>
                                        </MDBCardBody>
                                </MDBCol>
                                </MDBRow>
                </MDBCard>
        </MDBRow>


</MDBCol>


<MDBRow class="mx-0 my-0 py-0">
</MDBRow>
<RouterView />
</template>

<script>
import { ref } from "vue";
  import { MDBIcon, MDBCol, MDBRow, MDBBtn, MDBCard, MDBCardBody, MDBCardImg, MDBCardTitle, MDBCardText } from "mdb-vue-ui-kit";
  export default {
    components: {
      MDBCardImg,
      MDBIcon,
      MDBCol,
      MDBRow,
      MDBBtn,
      MDBCard,
      MDBCardBody,
      MDBCardTitle,
      MDBCardText,
    },
    setup() {
	const activeTabId1 = ref('ex1-1');

      return {
        activeTabId1,
	};
	},
     methods: {
	VRE () {
		window.open('https://vre.disc4all.eu/home/')
		},
	}
  };

</script>


<style>

.gradient-custom {
  /* fallback for old browsers */
  background: #001a8f;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(0, 26, 143, 0.75), rgba(255, 239, 61, 0.75));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #000221 , rgba(255, 239, 61, 0.75));

  height: 10px
}

.itn {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  color: rgba(255, 239, 61, 0.65)
}
.card-img {
 
  width: auto;
  height: 40%;
  object-fit: cover;


}
.itn2 {

  font-weight: bold;
  font-size: 15px;
  color: rgba(255, 239, 61, 0.45)

}

.button1 {
  background-color: rgba(255, 239, 61, 0.45);
  color: #000221;
  padding: 5px 15px 4px;
  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-size: 12px;

}

.slide {
  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-size: 17px;
  color: rgba(255, 239, 61)  !important;
  background-color:  #000221;
}


.fix {
  position: relative;
}


.boxes {
  color: white;
  font-family: 'Crimson Text', serif;
  font-size: 12px;
  background-color: #000221;

}

.button {
  background-color: rgba(255, 239, 61, 0.45);
  color: #000221;
  font-family: 'Crimson Text', serif;
  font-weight: 400;

}

.footer {

 background: linear-gradient(to right, #000221, rgba(255, 239, 61, 0.75));
}

.cartas {

  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-size: 12px;
  color: #000221;

}

.testo {

  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-size: 10px;
  color: #000221;


}


</style>                       
