/* eslint-disable no-console */


<template>
<MDBCol fluid class="slide">
        <MDBRow class="mx-5 py-5 slide">
                <div class="d-flex justify-content-left align-items-center h-100">
                <div class="ps-5">
                <h2>Sources and Docs for Disc4All Tools</h2>
                </div>
                </div>
        </MDBRow>

</MDBCol>

<MDBCol center class="slide px-5" >
<MDBRow class="mx-5 pb-5 slide">
<MDBTable captionTop id="myTable">
    <thead>
      <tr>
        <th scope="col" style="font-weight: bold; font-size: 20px">ESR</th>
        <th scope="col" style="font-weight: bold; font-size: 20px">Tool</th>
        <th scope="col" style="font-weight: bold; font-size: 20px">Description</th>
        <th scope="col" style="font-weight: bold; font-size: 20px">BioBB</th>
        <th scope="col" style="font-weight: bold; font-size: 20px">ReadTheDocs</th>
      </tr>
    </thead>
    <tbody class="descr">
      <tr>
        <th scope="row">4</th>
        <td style="font-size: 20px; font-weight: bold; font-style: normal">IVD phenotype classification tool</td>
        <td class="descr">Classifies IVD MRI entered as DICOMs in relevant groups of healthy and degenerated phenotypes and suggests likely associated tool molecular variants
</td>
        <td><i class="fab fa-github"></i></td>
        <td><i class="far fa-file-alt"></i></td>
      </tr>
      <tr>
        <th scope="row">5</th>
        <td style="font-size: 20px; font-weight: bold; font-style: normal">2D-3D IVD modelling tool</td>
        <td class="descr">Converts IVD planar MRI entered as DICOMs into a 3D IVD FE model in VTK format, with hexahedral structural mesh, and tissues identified as groups of elements.</td>
        <td><i class="fab fa-github"></i></td>
        <td><i class="far fa-file-alt"></i></td>
      </tr>
      <tr>
        <th scope="row">2</th>
        <td style="font-size: 20px; font-weight: bold; font-style: normal">AB-FE Multiscale mechanistic simulation software</td>
        <td class="descr">Integrated multiscale AB-FE simulation software, with capacity to (i) input FE and AB model parameters, (ii) automatically select specific cell regulation networks for the AB calculations, depending on the microenvironment predicted through the FE simulation software calculations, and (iii) generate ASCII file outputs to map relevant AB and FE calculation results on th FE mesh, at each iteration</td>
        <td><i class="fab fa-github"></i></td>
        <td><i class="far fa-file-alt"></i></td>
      </tr>
     <tr>
        <th scope="row">8</th>
        <td style="font-size: 20px; font-weight: bold; font-style: normal">Phenotype-gene biomarker-comorbodities association tool</td>
        <td class="descr"> Automatically proposes specific gene variants, in text format, that can be associated with different mechanistic cell signalling pathways, depending on MRI-based IVD phenotypes, sex and known comorbidity inputs, formulated as text
</td>
        <td><i class="fab fa-github"></i></td>
        <td><i class="far fa-file-alt"></i></td>
      </tr>
     <tr>
        <th scope="row">11</th>
        <td style="font-size: 20px; font-weight: bold; font-style: normal">Cell signaling pathway selection tool</td>
        <td class="descr">Automatically provides sets of disc cell signalling pathway network models, depending on gene variant inputs formulated as text selection tool</td>
        <td><i class="fab fa-github"></i></td>
        <td><i class="far fa-file-alt"></i></td>
      </tr>

     <tr>
        <th scope="row">12</th>
        <td style="font-size: 20px; font-weight: bold; font-style: normal">Cartilage endplate (CEP) integrity prediction tool</td>
        <td class="descr">Automatically calculates CEP composition changes (in %), in terms of proteoglycans and collagen, based on fluid flow RMS values (ASCII input file, flow values in mm/s) and gene variants (input as text)
</td>
        <td><i class="fab fa-github"></i></td>
        <td><i class="far fa-file-alt"></i></td>
      </tr>

     <tr>
        <th scope="row">14</th>
        <td style="font-size: 20px; font-weight: bold; font-style: normal">Multiscale LDD metamodelling tool</td>
        <td class="descr">Smart atlas that locally interpolates pre-calculated cell- and tissue-specific biological and physical fields on a 3D IVD mesh (VTK file), depending on specific geometrical (patient-specific 3D model geometry), mechanical (patient weight /height), clinical (MRI-metamodelling tool based IVD phenotype, known comorbidities) and molecular biomarker inputs (ASCII file)
</td>
        <td><i class="fab fa-github"></i></td>
        <td><i class="far fa-file-alt"></i></td>
      </tr>
     <tr>
        <th scope="row">15</th>
        <td style="font-size: 20px; font-weight: bold; font-style: normal">MRI-based advanced patient classification tool</td>
        <td class="descr"> Admits MRI, pain, gender and molecular (whenever available), and secondary data as inputs to predict the likely effect of gender classification tool and relative importance of psychological patient management, based on trained neural networks
</td>
        <td><i class="fab fa-github"></i></td>
        <td><i class="far fa-file-alt"></i></td>
      </tr>

    </tbody>
  </MDBTable>
</MDBRow>
</MDBCol>


<RouterView />
</template>

<script >
import { ref } from "vue";
  import { MDBTable,  MDBRow, MDBCol} from "mdb-vue-ui-kit";
  export default {
    components: {
      MDBTable,
      MDBCol,
      MDBRow,
    },
    methods: {
    myFunction () {
	
	}
    },
    setup() {
      const items1 = [
        {
	src: "https://i.postimg.cc/vThGWj1H/image.jpg",	
        alt: "...",
        label: "An EU infrastructure for multi-omics study in Intervertebral Disc Degeneration",
        caption: "Developing the first centralised platform",
	interval: 10
        },
        {
	src: "https://i.postimg.cc/vThGWj1H/image.jpg", 
	alt: "...",
        label: "Training network to advance integrated computational simulations in translational medicine",
        caption: "International ESRs community for research in spine diseases"
        },
        {
	src: "https://i.postimg.cc/vThGWj1H/image.jpg",
	alt: "...",
        label: "Developing the first centralised platform",
        caption:
            "For data sharing and pre-diagnosis tools for investigating Low Back Pain"
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1
      };
    }
  };

</script>


<style>

.gradient-custom {
  /* fallback for old browsers */
  background: #001a8f;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(0, 26, 143, 0.75), rgba(255, 239, 61, 0.75));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #000221 , rgba(255, 239, 61, 0.75));

  height: 10px
}

.itn {
  font-weight: bold;
  font-size: 15px;
  color: rgba(255, 239, 61, 0.65)
}
.card-img {
 
  width: auto;
  height: 40%;
  object-fit: cover;


}
.itn2 {

  font-weight: bold;
  font-size: 15px;
  color: rgba(255, 239, 61, 0.45)

}

.slide {
  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-size: 18px;
  color: white;
  background-color:  rgba(255, 239, 156);
}


.fix {
  position: relative;
}


.boxes {
  color: white;
  font-family: 'Crimson text', serif;
  font-size: 12px;
  background-color: #000221;

}

.table {

  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-variant: normal;
  font-size: 17px;
  color: #000221  !important;
  background-color:  rgba(255, 239, 156);


}


.button {
  background-color: rgba(255, 239, 61, 0.45);
  color: #000221;
 font-family: 'Crimson Text', serif;
  font-weight: 400;

}

.descr {
  color: #000221;
  font-size: 15px;
  font-style: italic;
}



.footer {

 background: linear-gradient(to right, #000221, rgba(255, 239, 61, 0.75));
}

.cartas {

  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-size: 12px;
  color: #000221;

}

.testo {

  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-size: 10px;
  color: #000221;


}


</style>                       
