<template>

    <Table />

</template>

<script>
// @ is an alias to /src
import Table from '@/components/Table.vue'

export default {
  name: 'Tableview',
  components: {
    Table
  }
}
</script>
