/* eslint-disable no-console */


<template>

<MDBRow >
<MDBCol class="slide">
<MDBRow class=" py-1">
  <div
      class="p-0 text-center bg-image rounded-9"
      style="
        background-image: url('');
        height: 300px;
        background-size: cover;
      "
    >
        <div class="mask">
        <div class="d-flex justify-content-center align-items-center h-100">
          <div class="title">
		<h3 class="title"> Discovering Disc4All</h3>
		<h4 class="subtitle"> Central Hub for the Data Access and Tools Management </h4>
			</div>
		</div>
		</div>
	</div>
	<!-- Background image -->

</MDBRow>
</MDBCol>


<MDBRow class="ms-1 py-2" style="background: linear-gradient(to bottom, #feffcd, white)">
        <h5 class="text-center py-5 frasi" > LBP is the largest cause of morbidity worldwide, yet there remains controversy as to the specific cause leading to poor treatment options and prognosis. <br> 
	Disc4All is an European Innovative Training Network dedicated to the study of intervertebral disc degeneration. Our mission is to integrate available data and knowledge to develop an holistic model of the functional unit of the spine. </h5>

</MDBRow>

<!--

<MDBRow style="background-color: #feffcd">
      <MDBCol class="ps-3 py-5 blob text-center"> The platform is designed to <br> streamline your research processes, <br> saving you time and resources.</MDBCol>
      <MDBCol class="ps-3 py-5 blob2 text-center">The simulations services <br> allow you to test and validate <br> your research hypotheses.</MDBCol>
      <MDBCol class="ps-3 py-5 pe-3 blob3 text-center"> To offer custom solutions <br> tailored to <br> your specific research needs.</MDBCol>
    </MDBRow>
-->

<MDBRow style="background-color: white" fluid>
<MDBCol style="background-color: white">
	<MDBRow class="ms-3 py-2 frasi" style="background-color: white">
        <h5 class="text-center py-5 description" style="color: black; ">  Comprehensive services tailored during the ITN for advance research
 </h5> 
		<MDBCardGroup style="background-color: white">
					<MDBCard text="center" class="mx-3 boxis">
						<MDBCardBody>
						<MDBCardText style="10px">
							Click here to access the Data Catalogue available for your data
						</MDBCardText>
							<MDBBtn tag="a" href="https://data.disc4all.eu/" class="button" >Disc4All Database	<MDBIcon icon="database" /> </MDBBtn>
						</MDBCardBody>
					</MDBCard>
					<MDBCard text="center" class="mx-3 boxis">
                                                <MDBCardBody>
                                                <MDBCardText style="10px">
                                                        Click here to access the Data Frameworks for compiling your data
                                                </MDBCardText>
                                                        <MDBBtn tag="a" href="https://catalogue.disc4all.eu/" class="button">Disc4All Catalogue <MDBIcon icon="database" /> </MDBBtn>
                                                </MDBCardBody>
                                        </MDBCard>
					<MDBCard text="center" class="mx-3 boxis">
						<MDBCardBody>
						<MDBCardText style="10px">
						Click here to access the Tools and Workflow platform for diagnosis investigation
						</MDBCardText>
						<MDBBtn class="button"> <RouterLink href="#" to="/launch">Launch Job       <MDBIcon icon="cubes" /></RouterLink></MDBBtn>
						</MDBCardBody>
					</MDBCard>
		</MDBCardGroup>
		</MDBRow>
</MDBCol>
</MDBRow>

<MDBRow class="mx-0 my-0 py-0">
	<div
		class="p-3 text-center bg-image"
		style="
			background-image: url('https://i.postimg.cc/wBZmG6fb/eudesign.png');
			height: 300px;
	background-size: cover;
		"
	>
	<div class="mask" style="background-color: rgba(0, 2, 33, 0.55);">
			<div class="d-flex justify-content-center align-items-center h-100">
			<div class="text-white">
				<h1 class="mb-3 itn">Marie Sklodowska Curie actions</h1>
				<h4 class="mb-3 itn2"> This is an ITN programmes within the framework of the European Commission’s Marie Sklodowska-Curie Actions support joint competitive programmes for training in research or joint doctorates.</h4>
				<MDBBtn tag="a" size="lg" lg	class="button" href="https://disc4all.upf.edu/" role="button"
					>Official Disc4All ITN</MDBBtn>
			</div>
			</div>
		</div>
	</div>
	<!-- Background image -->

</MDBRow>
</MDBRow>
<RouterView />
</template>

<script>
import { ref } from "vue";
  import { MDBIcon, MDBCol, MDBRow, MDBCardGroup, MDBBtn, MDBCard, MDBCardBody, MDBCardText } from "mdb-vue-ui-kit";
  export default {
	components: {
		MDBIcon,
		MDBCol,
		MDBRow,
		MDBBtn,
		MDBCard,
		MDBCardBody,
		MDBCardText,
		MDBCardGroup,
	},
	setup() {
		const items1 = [
		{
		src: "https://i.postimg.cc/vThGWj1H/image.jpg",	
			alt: "...",
			label: "An EU infrastructure for multi-omics study in Intervertebral Disc Degeneration",
			caption: "Developing the first centralised platform",
	interval: 10
		},
		{
		src: "https://i.postimg.cc/vThGWj1H/image.jpg", 
		alt: "...",
		label: "Training network to advance integrated computational simulations in translational medicine",
		caption: "International ESRs community for research in spine diseases"
		},
		{
		src: "https://i.postimg.cc/vThGWj1H/image.jpg",
		alt: "...",
		label: "Developing the first centralised platform",
		caption:
            "For data sharing and pre-diagnosis tools for investigating Low Back Pain"
        }
      ];
      const carousel1 = ref(0);
      return {
        items1,
        carousel1
      };
    }
  };

</script>


<style>

@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;1,400;1,600&display=swap');



.gradient-custom {
  /* fallback for old browsers */
  background: #001a8f;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(0, 26, 143, 0.75), #FEFFCD);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #000221 0, #FEFFCD);

  height: 10px
}

.itn {
  font-family: 'Crimson Text', serif;
  font-size: 15px;
  color: rgba(255, 239, 61, 0.65)
}

.itn2 {
  
  font-family: 'Crimson Text', serif;
  text-transform: lowercase;
  font-size: 15px;
  color: rgba(255, 239, 61, 0.45)

}


.title {

  font-family: 'Crimson Text', serif;
  font-size: 40px;
  font-weight: 600;
  color: #feffcd  !important;
  font-style: italic;

}

.subtitle {
  font-family: 'Crimson Text', serif;
  font-size: 40px;
  font-weight: 400;
  color: #feffcd  !important;
  font-style: normal;
	
}


.description {
  font-family: 'Crimson Text', serif;
  font-size: 25px;
  font-weight: 400;
  color: #000221  !important;
  font-style: normal;

}


.blob {
  font-family: 'Crimson text', serif;
  font-size: 16px;
  font-weight: 700;
  background-color: #feffcd ;
  background-position: center center; 
  color: #feffcd  !important;
  background-size: 70%;
  background-repeat: no-repeat;
  /* background-image: url('/home/mapo/vue-app/src/assets/nuovo-blob.svg')*/
}


.blob2 {
  font-family: 'Crimson Text', serif;
  font-size: 16px;
  font-weight: 600;
  color: #feffcd  !important;
  background-size: 95%;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-image: url('/home/mapo/vue-app/src/assets/blob2.svg')*/
}

.blob3 {
  font-family: 'Crimson Text', serif;
  font-size: 16px;
  font-weight: 600;
  color: #feffcd  !important;
  background-position: center center;
  background-size: 85%;
  background-repeat: no-repeat;
  /* background-image: url('/home/mapo/vue-app/src/assets/blob3.svg')*/
}



.h3 {
  font-size: 12px;
}

.slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('/home/mapo/vue-app/src/assets/nuovo.svg');
}


.fix {
  position: relative;
}


.frasi {

  color: #000221;
  /* background-color: #FEFFCD;*/
  font-family: 'Crimson Text', sans-serif;
  font-weight: 400;
  font-size: 28px;
  font-style: italic;


}


.boxis {
  color: white;
  font-family: 'Crimson Text', sans-serif;
  font-size: 18px;
  font-weight: 700;
  background-color: rgba(0, 2, 33, 0.6);
  width: 90%;
  border: 2px solid #000221;

}

.button {
  background-color: rgba(255, 239, 61);
  color: #000221;
  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;

}

.footer {

 background: linear-gradient(to right, #000221, rgba(255, 239, 61, 0.75));
}

.cartas {

  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-size: 12px;
  color: #000221;

}

.testo {

  font-family: 'Crimson Text', serif;
  font-weight: 400;
  font-size: 10px;
  color: #000221;


}


</style>                       
